import React, { useState, useEffect, useRef, useContext } from 'react';
import { List, Input, Button, Avatar, Space, Typography, Collapse, Spin, Row, Col, message, Flex } from 'antd';
import CopytoClipboard from '../../ButtonComponents/CopytoClipboard';
import DownloadDocButton from '../../ButtonComponents/DownloadDocButton';
import { SendOutlined, AudioOutlined } from '@ant-design/icons';
import { marked } from 'marked';
import './RFPCopilot.css';
import DOMPurify from 'dompurify';
import { useAuth } from '../../../auth/AuthContext';
import DummyFeedback from '../../../DummyComponent/DummyFeedback/DummyFeedback';
import botAvatar from '../../../assets/images/favicon.png';
import { AppContext } from '../../../context/AppContext';

const { Title, Link, Text } = Typography;
const { TextArea } = Input;
const Panel = Collapse.Panel;

// Speech recognition setup
const SpeechRecognition = window.SpeechRecognition || window.webkitSpeechRecognition;
let recognition = null;
if (SpeechRecognition) {
  recognition = new SpeechRecognition();
  recognition.continuous = false;
  recognition.lang = 'en-US';
  recognition.interimResults = false;
}

const initialMessages = [
  { id: 1, content: "Hello, I can help you with RFP.", role: 'assistant' },
];

const RFPCopilot = ({ refresh, setRefresh, setMessageList }) => {
  const { rfpCoState, updateRfpCoState } = useContext(AppContext);

  const userDetails = localStorage.getItem("userDetails");
  const user = userDetails ? JSON.parse(userDetails) : {};
  const auth = useAuth();
  const [messages, setMessages] = useState(rfpCoState?.messageList?.length > 0 ? rfpCoState?.messageList : initialMessages);
  const [newMessage, setNewMessage] = useState('');
  const [questionAsked, setQuestionAsked] = useState(false);
  const [questionList, setQuestionList] = useState([]);
  const [answerList, setAnswerList] = useState([]);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [recording, setRecording] = useState(false);
  const [RFPDocContent, setRFPDocContent] = useState('');
  const [loading, setLoading] = useState(rfpCoState?.messageList?.length > 0 ? false : true); // Loading state

  console.log(questionAsked, 'questionAsked')

  const refreshData = () => {
    setMessageList([]);
    updateRfpCoState('messageList', [])
    setMessages([]);
    setNewMessage('');
    setQuestionAsked(false);
    updateRfpCoState('questionAsked', false)
    setQuestionList([]);
    setAnswerList([]);
    setCurrentQuestionIndex(0);
    setRecording(false);
    setRFPDocContent('');
  };

  useEffect(() => {
    if (refresh) {
      refreshData();
      setRefresh(false);
    }
  }, [refresh]);

  const debounce = (fn, delay) => {
    let timer;
    return (...args) => {
      clearTimeout(timer);
      timer = setTimeout(() => fn(...args), delay);
    };
  };
  
  const updateQuestionAsked = debounce((question) => {
    setQuestionAsked(question);
  }, 300);
  
  useEffect(() => {
    if (rfpCoState?.questionAsked) {
      updateQuestionAsked(rfpCoState?.questionAsked);
    }
  }, [rfpCoState?.questionAsked]);

  // useEffect(() => {
  //   if (rfpCoState?.questionAsked) {
  //     console.log('ENTER HERE')
  //     setQuestionAsked(rfpCoState?.questionAsked)
  //   }
  // }, []);

  // useEffect(() => {
  //   if (messages?.length > 0) {
  //     updateRfpCoState('messageList', messages)
  //   }
  // }, [messages]);

  useEffect(() => {
    const scrollToBottom = () => {
      const lastMessageElem = document.querySelector('.chat-list .ant-list-item:last-child');
      if (lastMessageElem) {
        lastMessageElem.scrollIntoView({ behavior: 'smooth', block: 'end' });
      }
    };

    scrollToBottom();
  }, [messages]);

  useEffect(() => {
    if (rfpCoState?.messageList?.length === 0) {
      const initializeChat = async () => {
        const botResponse = await fetchBotResponse("I need a RFP.");
        const botMessage = {
          id: messages.length + 1,
          content: botResponse,
          role: 'assistant',
        };
        setMessages([botMessage]);
        setLoading(false); // Set loading to false once data is fetched
      };
      initializeChat();
    }
    else {
      setMessages(rfpCoState?.messageList);
      setLoading(false); // Set loading to false once data is fetched
    }
  }, []);

  const handleSendMessage = async (userResponse) => {
    if (!newMessage.trim()) { message.error("Please enter some text!"); return; }
    if (!questionAsked) {
      // User message
      const nextYouMessage = {
        id: messages.length + 1,
        content: newMessage,
        role: 'user',
      };

      // Temporary loading message for bot
      const loadingMessage = {
        id: messages.length + 2,
        content: "Loading bot's response...", // This could be replaced with a spinner or any placeholder
        role: 'assistant',
      };

      // Update state with user message and temporary loading message
      setMessages(messages => [...messages, nextYouMessage, loadingMessage]);
      setNewMessage('');

      //setMessageList(messages => [...messages, nextYouMessage, loadingMessage]);
      try {
        const botResponse = await fetchBotResponse(newMessage);

        // Replace the loading message with actual bot response
        // setMessages(messages => messages.map(message =>
        //   message.id === loadingMessage.id ? { ...message, content: botResponse } : message
        // ));
        // updateRfpCoState('messageList', [])
        // setMessageList(messages => messages.map(message =>
        //   message.id === loadingMessage.id ? { ...message, content: botResponse } : message
        // ));

        setMessages(currentMessages => {
          const updatedMessages = currentMessages.map(message =>
            message.id === loadingMessage.id
              ? { ...message, content: botResponse }
              : message
          );

          // Update sapAiState with the new message list
          updateRfpCoState('messageList', updatedMessages);

          // Return the updated messages state
          return updatedMessages;
        });

      } catch (error) {
        console.error("Fetching bot's response failed:", error);
        // Update loading message with error message
        setMessages(currentMessages => {
          const updatedMessages = currentMessages.map(message =>
            message.id === loadingMessage.id
              ? { ...message, content: "Failed to load response" }
              : message
          );

          // Update sapAiState with the new message list
          updateRfpCoState('messageList', updatedMessages);

          // Return the updated messages state
          return updatedMessages;
        });
      }
      return;
    }
    // User response to a question
    const nextUserMessage = {
      id: messages.length + 1,
      content: userResponse,
      role: 'user'
    };
    setAnswerList(answerList => [...answerList, userResponse]);
    // Check if there are more questions
    if (currentQuestionIndex < questionList.length - 1) {
      const botMessage = {
        id: messages.length + 2,
        content: questionList[currentQuestionIndex + 1],
        role: 'assistant',
      };
      // setMessages(messages => [...messages, nextUserMessage, botMessage]);
      setMessages(currentMessages => {
        const updatedMessages = [...currentMessages, nextUserMessage, botMessage];

        // Update sapAiState with the new message list
        updateRfpCoState('messageList', updatedMessages);

        // Return the updated messages state
        return updatedMessages;
      });

      setNewMessage('');
      setCurrentQuestionIndex(current => current + 1);
    } else {
      const loadingMessage = {
        id: messages.length + 2,
        content: "Loading RFP response...", // This could be replaced with a spinner or any placeholder
        role: 'assistant',
      };
      setMessages(messages => [...messages, nextUserMessage, loadingMessage]);
      setNewMessage('');
      try {
        const botResponse = await fetchRFPResponse([...answerList, nextUserMessage.content]);

        // Replace the loading message with actual bot response
        // setMessages(messages => messages.map(message =>
        //   message.id === loadingMessage.id ? { ...message, content: botResponse } : message
        // ));
        // setMessageList(messages => messages.map(message =>
        //   message.id === loadingMessage.id ? { ...message, content: botResponse } : message
        // ));
        setMessages(currentMessages => {
          const updatedMessages = currentMessages.map(message =>
            message.id === loadingMessage.id
              ? { ...message, content: botResponse }
              : message
          );

          // Update sapAiState with the updated message list
          updateRfpCoState('messageList', updatedMessages);

          // Return the updated messages state
          return updatedMessages;
        });

      } catch (error) {
        console.error("Fetching bot's response failed:", error);
        // Update loading message with error message
        // setMessages(messages => messages.map(message =>
        //   message.id === loadingMessage.id ? { ...message, content: "Failed to load response" } : message
        // ));
        // setMessageList(messages => messages.map(message =>
        //   message.id === loadingMessage.id ? { ...message, content: "Failed to load response" } : message
        // ));
        setMessages(currentMessages => {
          const updatedMessages = currentMessages.map(message =>
            message.id === loadingMessage.id
              ? { ...message, content: "Failed to load response" }
              : message
          );

          // Update sapAiState with the updated message list
          updateRfpCoState('messageList', updatedMessages);

          // Return the updated messages state
          return updatedMessages;
        });

      }
      setQuestionAsked(false);
      updateRfpCoState('questionAsked', false)
      setCurrentQuestionIndex(0);
      setAnswerList([]);
    }

  };

  const fetchBotResponse = async (query) => {
    try {
      let apiEndpoint = "rfp_suggested_question";

      let formData = new FormData();
      formData.append("query", query);

      const response = await fetch(`${process.env.REACT_APP_API_URL}/${apiEndpoint}`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${auth.user['access_token']}`,
        },
        body: formData
      });
      if (response?.ok) {
        const data = await response?.json();
        if (data?.error) {
          return data?.error;
        }
        if (data?.question_list) {
          setQuestionAsked(true);
          updateRfpCoState('questionAsked', true)
          setQuestionList(data?.question_list);
          return data?.question_list[0];
        }
        return data?.response;
      }
    } catch (err) {
      return err;
    }
  };

  const fetchRFPResponse = async (_answerList) => {
    try {
      let apiEndpoint = "get_rfp_response";

      let formData = new FormData();

      // Concatenate all questions and answers into a single string
      let conversation = "";
      for (let i = 0; i < questionList.length; i++) {
        conversation += `Question: ${questionList[i]}\nAnswer: ${_answerList[i]}\n\n`;
      }
      formData.append("conversation", conversation);
      formData.append("companyName", _answerList[0]);
      const response = await fetch(`${process.env.REACT_APP_API_URL}/${apiEndpoint}`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${auth.user['access_token']}`,
        },
        body: formData
      });
      if (response?.ok) {
        const data = await response?.json();
        if (data?.error) {
          return data?.error;
        }
        setRFPDocContent(data?.rfpDocMarkdown);
        return data?.response;
      }
    } catch (err) {
      return err;
    }
  };

  useEffect(() => {
    if (!recognition) return;

    recognition.onresult = (event) => {
      const transcript = Array.from(event.results)
        .map((result) => result[0])
        .map((result) => result.transcript)
        .join('');
      setNewMessage(transcript);
      setRecording(false);
    };

    recognition.onend = () => {
      setRecording(false);
    };

    recognition.onerror = (event) => {
      console.error('SpeechRecognition error:', event.error);
      setRecording(false);
    };


  }, []);

  const handleRecord = () => {
    console.log('ENter in record')
    if (!SpeechRecognition) {
      console.error('SpeechRecognition is not supported in this browser.');
      return;
    }
    if (recording) {
      recognition.stop();
      setRecording(false);
    } else {
      recognition.start();
      setRecording(true);
    }
  };

  const markdownToHtml = (markdown) => {
    const rawHtml = marked.parse(markdown);
    return DOMPurify.sanitize(rawHtml);
  };

  return (
    <div>
      {loading ? (
        <div style={{ textAlign: 'center' }}>
          <Spin size="large" />
        </div>
      ) : (
        <>
          <div className="chat-container">
            <List
              className="chat-list"
              dataSource={messages}
              renderItem={(item, index) => (
                <List.Item key={item?.id} className={`chat-message ${item?.role} custom-list-item`} style={{ padding: "10px", borderRadius: "10px" }}>
                  <List.Item.Meta
                    avatar={item?.role === 'assistant' ? <Avatar src={botAvatar} style={{ backgroundColor: "#000000", padding: "5px" }} className="avatar-left" /> : <Avatar className="avatar-right" style={{ backgroundColor: "#fff", color: "#0074D9", marginRight: "10px" }}>{user?.name?.split(" ").length > 1
                      ? `${user?.name?.split(" ")[0][0]}${user?.name?.split(" ")[1][0]}`
                      : `${user?.name?.split(" ")[0][0]}${user?.name?.split(" ")[0][1]}`}</Avatar>}
                    title={item.role === 'user' ? 'You' : 'Eerly AI Copilot'}
                    description={
                      item?.content === "Loading bot's response..." ?
                        <Spin />
                        :
                        item?.content === "Loading RFP response..." ?
                          <Row>
                            <Col span={24}>
                              <Flex style={{ justifyContent: 'flex-start' }}>
                                <Spin />
                                <Text strong style={{ marginLeft: "15px" }}>Generating RFP response</Text>
                              </Flex>
                            </Col>
                          </Row>
                          :
                          <>
                            <div className="message-content" dangerouslySetInnerHTML={{ __html: markdownToHtml(item?.content) }} />
                            {item?.role === 'assistant' &&
                              <Row>
                                <Col lg={12} md={12} sm={12} xs={4}>
                                  <Flex style={{ justifyContent: 'flex-start' }}>
                                    <CopytoClipboard text={item?.content} />
                                    {item?.content?.includes("Request for Proposal") && <DownloadDocButton markdownText={RFPDocContent} fileName="RFP Response" />}
                                  </Flex>
                                </Col>
                                <Col lg={12} md={12} sm={12} xs={20}>
                                  <DummyFeedback />
                                </Col>
                              </Row>
                            }
                          </>
                    }
                  />
                </List.Item>
              )}
            />
          </div>
          <Space.Compact style={{ width: '100%', marginTop: "20px" }}>
            {questionAsked ? (
              <>
                <Button type="primary" onClick={handleRecord} icon={<AudioOutlined />} danger={recording} style={{ height: '100px' }} />
                <TextArea
                  style={{ resize: 'none', height: '100px' }}
                  value={newMessage}
                  onChange={(e) => setNewMessage(e.target.value)}
                  onPressEnter={() => handleSendMessage(newMessage)}
                  placeholder="Type your response here..."
                />
                <Button type="primary" onClick={() => handleSendMessage(newMessage)} icon={<SendOutlined />} style={{ height: '100px' }} />
              </>
            ) : (
              <>
                <Button type="primary" onClick={handleRecord} icon={<AudioOutlined />} danger={recording} />
                <Input
                  value={newMessage}
                  onChange={(e) => setNewMessage(e.target.value)}
                  onPressEnter={() => handleSendMessage(newMessage)}
                  placeholder="Ask your query..."
                />
                <Button type="primary" onClick={async () => {
                  await handleSendMessage(newMessage)
                }} icon={<SendOutlined />} />
              </>
            )}
          </Space.Compact>
        </>
      )}
    </div>
  );
};

export default RFPCopilot;
