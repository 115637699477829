// src/pages/VerifyEmail.tsx
import React from 'react';
import { Link } from 'react-router-dom';
import { Layout, Row, Col, Typography, Card } from 'antd';
import AppFooter from '../../AppFooter/AppFooter';
// import bgImage from '../../assets/images/bg (6).jpg';
import logo from '../../../assets/images/Eerly_logo_color-transparent.png';

const { Content } = Layout;
const { Title, Text } = Typography;

const VerifyEmail = () => {
  return (
    <Layout style={{ minHeight: '100vh', backgroundColor: '#E9E9EB' }}>
      <Content style={{ padding: '0', textAlign: 'center' }}>
        <Row justify="center" align="middle">
          <Col xs={24} sm={24} lg={7}></Col>
          <Col xs={24} sm={24} lg={10} style={{ paddingTop: '12%', padding: '20px' }}>
            <Card style={{ padding: '30px', boxShadow: '0px 0px 23px -1px rgba(133,133,133,1)' }}>
              <img src={logo} alt="Eerly Logo" width={'150px'} />
              <Title style={{ marginTop: '-10px', marginBottom: '50px', color: '#011470' }} level={4}>
                Eerly AI Brain by Eerly AI
              </Title>

              <Title level={3}>Verify Your Email</Title>
              <Text>
                Thank you for signing up! A verification email has been sent to your email address. Please check your inbox and click on the verification link to activate your account.
              </Text>

              <Text style={{ display: 'block', marginTop: '20px' }}>
                If you did not receive the email, please check your spam folder.
                {/* or <Link to="/resend-verification">click here</Link> to resend the verification email. */}
              </Text>
            </Card>
          </Col>
          <Col xs={24} sm={24} lg={7}></Col>
        </Row>
        <div style={{position: "fixed", bottom: 10, width: "100%"}}>
          <AppFooter />
        </div>
      </Content>
    </Layout>
  );
};

export default VerifyEmail;
