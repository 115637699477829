// src/pages/Login.tsx
import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Layout, Row, Col, Form, Typography, Input, Button, Card, Divider } from 'antd';
import AppFooter from '../AppFooter/AppFooter';
import bgImage from '../../assets/images/login-banner.jpg';
import logo from '../../assets/images/Eerly_logo_color-transparent.png';
import { useAuth } from '../../auth/AuthContext';
import LinkedInLogin from '../LinkedInLogin/LinkedInLogin';

const { Content } = Layout;
const { Title } = Typography;

const Login = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const auth = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    // Redirect to the dashboard if the user is already logged in
    if (auth.user) {
      navigate('/eerly-brain');
    }
  }, [auth.user, navigate]);

  const handleSubmit = async () => {
    setLoading(true);
    try {
      const newUser = await auth.login(username, password);
      setLoading(false);
      const firstLogin = newUser.first_login;
      if (firstLogin) navigate('/change-password');
      else navigate('/eerly-brain');
    } catch (error) {
      console.error('Login failed:', error);
      setLoading(false);
    }
  };

  return (
    <Layout style={{ minHeight: '100vh', backgroundColor: '#E9E9EB' }}>
      <Content style={{ padding: '0', textAlign: 'center' }}>
        <Row justify="center" align="middle">
          <Col lg={{ span: 16 }} xs={{ span: 0 }} style={{ paddingTop: '5%' }}>
            <img src={bgImage} alt="" width={'100%'} />
          </Col>
          <Col lg={{ span: 6 }} xs={{ span: 24 }} style={{ paddingTop: '10vh', padding: '20px' }}>
            <Card style={{ padding: '30px', boxShadow: '0px 0px 23px -1px rgba(133,133,133,1)' }}>
              <img src={logo} alt="" width={'150px'} />
              <Title style={{ marginTop: '-10px', marginBottom: '50px', color: '#011470' }} level={4}>
                Please Login to Access Eerly AI Brain
              </Title>
              <Form style={{ textAlign: 'left' }} layout="vertical" onFinish={handleSubmit}>
                <Form.Item
                  label="Username"
                  name="username"
                  rules={[
                    {
                      required: true,
                      message: 'Please input your username!',
                    },
                  ]}
                >
                  <Input placeholder="Username" value={username} onChange={(e) => setUsername(e.target.value)} />
                </Form.Item>
                <Form.Item
                  label="Password"
                  name="password"
                  rules={[
                    {
                      required: true,
                      message: 'Please input your password!',
                    },
                  ]}
                >
                  <Input.Password
                    type="password"
                    placeholder="Password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                </Form.Item>
                <p
                  className="font-semibold text-muted"
                  style={{ textAlign: 'right', marginBottom: '-15px', marginTop: '-10px' }}
                >
                  <Link to="/forgot-password" className="text-dark font-bold">
                    Forgot Password?
                  </Link>
                </p>
                <Form.Item>
                  <Button
                    type="primary"
                    style={{ width: '100%', marginTop: '30px' }}
                    htmlType="submit"
                    loading={loading}
                  >
                    SIGN IN
                  </Button>
                </Form.Item>
                <Divider>OR</Divider>
                <div style={{ marginTop: '20px', textAlign: "center" }}>
                  <h3>Login with</h3>
                  <LinkedInLogin />      
                </div>          
              </Form>
            </Card>           
            <p className="font-semibold text-muted" style={{ textAlign: 'center', marginTop: "30px" }}>
              Don't have an account?{' '}
              <Link to="/signup" className="text-dark font-bold">
                Sign Up
              </Link>
            </p>
          </Col>
          <Col lg={{ span: 2 }} xs={{ span: 0 }}></Col>
        </Row>
      </Content>
      <AppFooter />
    </Layout>
  );
};

export default Login;
