// Chatbot.tsx
import React, { useState, useEffect, useRef, useContext } from 'react';
import './ChatbotEerlyInsight.css';
import { marked } from 'marked';
import DOMPurify from 'dompurify';
import { List, Input, Button, Avatar, Space, Card, Typography, Collapse, Spin, Row, Col, Flex, Switch, Modal, message, Upload, Alert, Tooltip } from 'antd';
import CopytoClipboard from '../ButtonComponents/CopytoClipboard';
import DownloadButton from '../ButtonComponents/DownloadButton';
import { SendOutlined, PaperClipOutlined, FilePdfOutlined, DownloadOutlined, AudioOutlined } from '@ant-design/icons';
import botAvatar from '../../assets/images/favicon.png';
import DummyFeedback from '../../DummyComponent/DummyFeedback/DummyFeedback';
import { useAuth } from '../../auth/AuthContext';
import dummyData from './data.json';
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import { AppContext } from '../../context/AppContext';

const { Title, Link, Text } = Typography;
const Panel = Collapse.Panel;

const initialMessages = [
    { id: 1, content: "Hello, What can I do for you?", role: 'assistant' },
];

const markdownToHtml = (markdown) => {
    const rawHtml = marked.parse(markdown);
    return DOMPurify.sanitize(rawHtml);
};


const ChatbotEerlyInsight = ({ user }) => {
    const { eerlyInsightState, updateEerlyInsightState } = useContext(AppContext);
    const [messages, setMessages] = useState(eerlyInsightState?.messageList?.length > 0 ? eerlyInsightState?.messageList : initialMessages);
    const auth = useAuth();
    const [newMessage, setNewMessage] = useState('');
    const [chatInitiated, setChatInitiated] = useState(eerlyInsightState?.chatInitiated ? eerlyInsightState?.chatInitiated : false);

    useEffect(() => {
        const scrollToBottom = () => {
            const lastMessageElem = document.querySelector('.chat-list .ant-list-item:last-child');
            if (lastMessageElem) {
                lastMessageElem.scrollIntoView({ behavior: 'smooth', block: 'end' });
            }
        };

        scrollToBottom();
    }, [messages]);

    const handleInputChange = (e) => {
        setNewMessage(e.target.value);
    };

    const fetchData = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/get_eerly_insights?query=${newMessage}`, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${auth.user['access_token']}`,
                },
                body: '' // since -d is empty in your curl command
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            const data = await response.json();
            console.log(data, 'DATA DATA'); // handle the response data here
            return data; // Return the data here
        } catch (error) {
            console.error('There has been a problem with your fetch operation:', error);
            return null; // Optionally return null or an error object
        }
    };


    const handleSendMessage = async () => {
        if (!newMessage.trim()) return;
        //setSuggestedQuestions([])
        // User message
        const nextYouMessage = {
            id: messages.length + 1,
            content: newMessage,
            role: 'user',
        };

        // Temporary loading message for bot
        const loadingMessage = {
            id: messages.length + 2,
            content: "Loading bot's response...", // This could be replaced with a spinner or any placeholder
            role: 'assistant',
        };
        //setMessages([{ content: dummyData.response }])



        //Update state with user message and temporary loading message
        setMessages(currentMessages => {
            const updatedMessages = [...currentMessages, nextYouMessage, loadingMessage]; // Create the new messages array

            // Update sapAiState with the new message list
            //updateSapAiState('messageList', updatedMessages);

            // Return the updated messages state
            return updatedMessages;
        });
        setNewMessage('');

        //setMessageList(messages => [...messages, nextYouMessage, loadingMessage])

        try {

            const res = await fetchData();

            console.log(res, 'res res')

            const getCsvData = (csv) => {
                console.log(csv, 'CSV')
                if (csv) {
                    // Decode base64
                    const binaryString = window?.atob(csv);

                    // Convert binary string to array buffer
                    const len = binaryString.length;
                    const bytes = new Uint8Array(len);
                    for (let i = 0; i < len; i++) {
                        bytes[i] = binaryString.charCodeAt(i);
                    }

                    // Parse the ArrayBuffer as Excel
                    const workbook = XLSX.read(bytes, { type: 'array' });

                    // Assume the first sheet
                    const firstSheetName = workbook.SheetNames[0];
                    const worksheet = workbook.Sheets[firstSheetName];

                    // Convert to JSON for easy handling
                    const data = XLSX.utils.sheet_to_json(worksheet);
                    return data;
                } else {
                    return ''
                }
            }

            const getContent = () => {
                let arr = [];
                res?.visualisation?.length > 0 && res?.visualisation?.map((data) => {
                    arr.push({
                        synthesized_response: data?.rationale,
                        index: data?.index,
                        plot: `data:image/png;base64, ${data?.plot}`
                    })
                })
                return arr;
            }

            setMessages(currentMessages => {
                const updatedMessages = currentMessages.map(message =>
                    message.id === loadingMessage.id
                        ? { ...message, synthesized_response: res?.summary, first_img: res?.visualisation?.[0]?.plot ? `data:image/png;base64, ${res?.visualisation?.[0]?.plot}` : null, content: [...getContent(), { csvData: getCsvData(res?.raw_data) }] }  // Update the message content
                        : message
                );

                // Update sapAiState with the new message list
                updateEerlyInsightState('messageList', updatedMessages);

                // Return the updated messages state
                return updatedMessages;
            });

            // setMessages(currentMessages => {
            //     const updatedMessages = [...currentMessages, nextYouMessage, { content: res?.summary, id: 4, role: "assistant" }, { id: 5, role: "assistant", content: getContent() },
            //     { id: 6, role: "assistant", content: [{ csvData: getCsvData(res?.raw_data) }] }
            //     ]; // Create the new messages array

            //     // Update sapAiState with the new message list
            //     //updateSapAiState('messageList', updatedMessages);

            //     // Return the updated messages state
            //     console.log(updatedMessages, 'updatedMessages')
            //     return updatedMessages;
            // });

        } catch (error) {
            console.error("Fetching bot's response failed:", error);
            // Update loading message with error message
            setMessages(currentMessages => {
                const updatedMessages = currentMessages.map(message =>
                    message.id === loadingMessage.id
                        ? { ...message, content: "Failed to load response" }  // Update the message content
                        : message
                );

                // Update sapAiState with the new message list
                updateEerlyInsightState('messageList', updatedMessages);

                // Return the updated messages state
                return updatedMessages;
            });
        }



        // setMessages(currentMessages => {
        //     const updatedMessages = [...currentMessages, nextYouMessage, { content: res?.summary, id: 4, role: "assistant" }, { id: 5, role: "assistant", content: getContent() },
        //     { id: 6, role: "assistant", content: [{ csvData: getCsvData(res?.raw_data) }] }
        //     ]; // Create the new messages array

        //     // Update sapAiState with the new message list
        //     //updateSapAiState('messageList', updatedMessages);

        //     // Return the updated messages state
        //     console.log(updatedMessages, 'updatedMessages')
        //     return updatedMessages;
        // });

    };

    console.log(messages, 'messages')

    return (
        <>
            <div className="chat-container">
                {!chatInitiated ?
                    <Row
                        type="flex"
                        justify="center"
                        align="middle"
                        style={{ height: '60vh' }}
                    >
                        <Col span={24} style={{ textAlign: "center", marginBottom: "-6vh" }}>
                            <Title level={3}>Hi, Welcome to Eerly Insight!</Title>
                            <img src='./eerly-insight-4.jpeg' className='eerly-banner-img' />
                        </Col>
                    </Row>
                    :
                    <List
                        className="chat-list"
                        dataSource={messages}
                        renderItem={(item, index) => {
                            console.log(item, 'item output')
                            const previousMessage = messages[index - 1];
                            return (
                                <List.Item key={item?.id} className={`chat-message ${item?.role} custom-list-item`} style={{ padding: "10px", borderRadius: "10px" }}>
                                    <List.Item.Meta
                                        avatar={item?.role === 'assistant' ? <Avatar src={botAvatar} style={{ backgroundColor: "#000000", padding: "5px" }} className="avatar-left" /> : <Avatar className="avatar-right" style={{ backgroundColor: "#fff", color: "#0074D9", marginRight: "10px" }}>{user.name.split(" ").length > 1
                                            ? `${user?.name.split(" ")[0][0]}${user?.name.split(" ")[1][0]}`
                                            : `${user?.name.split(" ")[0][0]}${user?.name.split(" ")[0][1]}`}</Avatar>}
                                        title={item?.role === 'user' ? 'You' : 'Eerly Insight'}
                                        description={item?.content === "Loading bot's response..." ?
                                            <Spin />
                                            : item?.role === 'user' ?
                                                item?.content
                                                : typeof item?.content === 'object' ?
                                                    <>
                                                        {item?.synthesized_response &&
                                                            <>
                                                                <div className="message-content" dangerouslySetInnerHTML={{ __html: markdownToHtml(item?.synthesized_response) }} />
                                                                <Row>
                                                                    <Col lg={12} md={12} sm={12} xs={4}>
                                                                        <Flex style={{ justifyContent: 'flex-start' }}>
                                                                            <CopytoClipboard text={item?.synthesized_response} />
                                                                            {/* {isProjectPlanInPreviousMessage &&
                                                                    <>
                                                                        {loading ?
                                                                            <Spin />
                                                                            :
                                                                            <DownloadOutlined style={{ marginRight: "10px" }} onClick={async () => await fetchProjectPlan(item.content)} />
                                                                        }
                                                                    </>} */}
                                                                        </Flex>
                                                                    </Col>
                                                                    <Col lg={12} md={12} sm={12} xs={20}>
                                                                        <DummyFeedback />
                                                                    </Col>
                                                                </Row>
                                                            </>
                                                        }

                                                        {item?.first_img && <img className='base-64-img first-img' src={item?.first_img} alt='img' />}

                                                        <Collapse accordion>

                                                            {item?.content?.some(response => response?.plot) && ( // Check if any response has a plot
                                                                <Panel header={"view more visualization"}>
                                                                    {item?.content?.map((response, index) => (
                                                                        response?.index !== 0 && response?.plot && ( // Add the condition here
                                                                            <>
                                                                                <img className='base-64-img' src={response?.plot} alt='img' />
                                                                                <div className="message-content" dangerouslySetInnerHTML={{ __html: markdownToHtml(response?.synthesized_response || '') }} />

                                                                                <Row>
                                                                                    <Col lg={12} md={12} sm={12} xs={4}>
                                                                                        <Flex style={{ justifyContent: 'flex-start' }}>
                                                                                            <CopytoClipboard text={response?.synthesized_response || ''} />
                                                                                        </Flex>
                                                                                    </Col>
                                                                                    {/* <Col lg={12} md={12} sm={12} xs={20}>
                            <DummyFeedback />
                        </Col> */}
                                                                                </Row>
                                                                            </>
                                                                        )
                                                                    ))}
                                                                </Panel>
                                                            )}


                                                            {item?.content?.map((response, index) => (
                                                                <>
                                                                    {response?.csvData &&
                                                                        <Panel header={"view source data"}>
                                                                            <table border="1" cellPadding="10" className='data-tbl'>
                                                                                <thead>
                                                                                    <tr>
                                                                                        {Object.keys(response?.csvData[0])?.map((key, index) => (
                                                                                            <th key={index}>{key}</th>
                                                                                        ))}
                                                                                    </tr>
                                                                                </thead>
                                                                                <tbody>
                                                                                    {response?.csvData?.map((row, rowIndex) => (
                                                                                        <tr key={rowIndex}>
                                                                                            {Object.values(row).map((cell, cellIndex) => (
                                                                                                <td key={cellIndex}>{cell}</td>
                                                                                            ))}
                                                                                        </tr>
                                                                                    ))}
                                                                                </tbody>
                                                                            </table> </Panel>


                                                                    }</>

                                                            ))}




                                                        </Collapse>
                                                    </>
                                                    : (<div>
                                                        <div className="message-content" dangerouslySetInnerHTML={{ __html: markdownToHtml(item?.content) }} />
                                                        <Row>
                                                            <Col lg={12} md={12} sm={12} xs={4}>
                                                                <Flex style={{ justifyContent: 'flex-start' }}>
                                                                    <CopytoClipboard text={item?.content} />
                                                                    {/* {isProjectPlanInPreviousMessage &&
                                                                    <>
                                                                        {loading ?
                                                                            <Spin />
                                                                            :
                                                                            <DownloadOutlined style={{ marginRight: "10px" }} onClick={async () => await fetchProjectPlan(item.content)} />
                                                                        }
                                                                    </>} */}
                                                                </Flex>
                                                            </Col>
                                                            <Col lg={12} md={12} sm={12} xs={20}>
                                                                <DummyFeedback />
                                                            </Col>
                                                        </Row>

                                                    </div>)}
                                    />
                                </List.Item>
                            )
                        }
                        }
                    />}
            </div>
            <Space.Compact style={{ width: '100%', marginTop: "20px" }}>
                <Input value={newMessage}
                    onChange={handleInputChange}
                    onPressEnter={async () => {
                        setChatInitiated(true);
                        updateEerlyInsightState('chatInitiated', true)
                        await handleSendMessage()
                    }}
                    placeholder="Ask your query..." />
                <Button type="primary" onClick={async () => {
                    setChatInitiated(true);
                    updateEerlyInsightState('chatInitiated', true)
                    await handleSendMessage()
                }} icon={<SendOutlined />} />
            </Space.Compact>
        </>
    )
}

export default ChatbotEerlyInsight
