import React, { useContext, useEffect, useState } from 'react';
import { Layout, Row, Col, Typography, Switch, Tooltip, Radio, Flex } from 'antd';
import { AppContext } from '../../../context/AppContext';
import Chatbot from "../../Chatbot/Chatbot";
import DownloadButton from '../../ButtonComponents/DownloadButton';
import ChatHistory from '../ChatHistory/ChatHistory';
import "./ChatWithData.css";
const { Content } = Layout;
const { Title } = Typography;

const ChatWithData = () => {
  const { sapAiState, updateSapAiState } = useContext(AppContext);
  console.log(sapAiState, 'sapAiState frm chat with data')
  //const [messageList, setMessageList] = useState([]);
  // const [suggestedQuestionsOn, setSuggestedQuestionsOn] = useState(false);
  //const [initialized, setInitialized] = useState(false);
  //const [value, setValue] = useState('New Chat');
  // const options = [
  //   { label: 'New Chat', value: 'New Chat' },
  //   { label: 'Chat History', value: 'Chat History' },
  // ];

  const onChange = ({ target: { value } }) => {
    console.log('radio3 checked', value);
    //setValue(value);
    updateSapAiState('value', value)
  };

  const onChangeSwitch = (checked) => {
    //setSuggestedQuestionsOn(checked);
    updateSapAiState('suggestedQuestionsOn', checked)
  };

  const userDetails = localStorage.getItem("userDetails");
  const user = userDetails && JSON.parse(userDetails);

  useEffect(() => {
    if (!sapAiState?.initialized && user) {
      if (user.plan !== 'Free' || user.role === 'admin') {
        //setSuggestedQuestionsOn(true);
        updateSapAiState('suggestedQuestionsOn', true)
      }
      //setInitialized(true);
      updateSapAiState('initialized', true)
    }
  }, [user, sapAiState?.initialized]);

  return (
    <Content style={{ overflow: 'initial' }} className="site-layout-background mobile-layout-margin">
      <>
        <Row>
          <Col lg={12} sm={24} xs={24}>
            <Flex>
              <Title level={3} style={{ marginTop: 0, marginRight: "10px" }}>SAP AI Consultant</Title>
              <Radio.Group options={sapAiState?.options} onChange={onChange} value={sapAiState?.value} optionType="button" buttonStyle="solid" />
            </Flex>

          </Col>
          <Col lg={12} sm={24} xs={24}>
            <div style={{ display: 'flex', justifyContent: 'flex-end', paddingRight: "10px" }}>
              {/* <Switch
                onChange={onChangeSwitch}
                checked={sapAiState?.suggestedQuestionsOn}
                checkedChildren="Prompt Suggestion On"
                unCheckedChildren="Prompt Suggestion Off"
                style={{ marginRight: "15px", marginTop: "5px" }}
                className='desktop-view-inline'
                disabled={user && user.plan === 'Free' && user.role !== 'admin'}
              /> */}

              <div className="prompt-suggetions-main">
                <Title className="prompt-suggetions-title" level={5}>Prompt Suggestion</Title>
                <Switch
                  onChange={onChangeSwitch}
                  checked={sapAiState?.suggestedQuestionsOn}
                  //   checkedChildren="Prompt Suggestion On"
                  //   unCheckedChildren="Prompt Suggestion Off"
                  size="small"
                  disabled={
                    userDetails && userDetails.plan === "Free" && userDetails.role !== "admin"
                  }
                />
              </div>
              <Tooltip placement='top' title='Prompt Suggestion' className='mobile-view'>
                <Switch
                  onChange={onChangeSwitch}
                  checked={sapAiState?.suggestedQuestionsOn}
                  checkedChildren="On"
                  unCheckedChildren="Off"
                  style={{ marginRight: "15px", marginTop: "5px" }}
                  className='mobile-view'
                  disabled={user && user.plan === 'Free' && user.role !== 'admin'}
                />
              </Tooltip>
              <DownloadButton markdownText={""} messages={sapAiState?.messageList} disabled={user && user.plan !== 'Tier 3' && user.role !== 'admin'} />
            </div>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            {sapAiState?.value === "New Chat" ?
              <Chatbot user={user} />
              :
              <ChatHistory user={user} />
            }
          </Col>
        </Row>
      </>
    </Content>
  );
};

export default ChatWithData;
