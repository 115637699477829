import React, { useState, useEffect, useContext } from 'react';
import { List, Input, Button, Avatar, Space, Typography, Spin, Row, Col, message, Upload, Radio } from 'antd';
import CopytoClipboard from '../../ButtonComponents/CopytoClipboard';
import DownloadDocButton from '../../ButtonComponents/DownloadDocButton';
import { SendOutlined, AudioOutlined, UploadOutlined } from '@ant-design/icons';
import { marked } from 'marked';
import './ROMCopilot.css';
import DOMPurify from 'dompurify';
import { useAuth } from '../../../auth/AuthContext';
import DummyFeedback from '../../../DummyComponent/DummyFeedback/DummyFeedback';
import botAvatar from '../../../assets/images/favicon.png';
import { AppContext } from '../../../context/AppContext';
const { Text } = Typography;
const { TextArea } = Input;

const SpeechRecognition = window.SpeechRecognition || window.webkitSpeechRecognition;
let recognition = null;
if (SpeechRecognition) {
    recognition = new SpeechRecognition();
    recognition.continuous = false;
    recognition.lang = 'en-US';
    recognition.interimResults = false;
}

const initialMessages = [
  { id: 1, content: "Welcome to ROM Co-Pilot. Do you have a ROM file to upload?", role: 'assistant' },
];

const ROMCopilot = ({ setMessageList, refresh, setRefresh }) => {
  const { romCoState, updateRomCoState } = useContext(AppContext);
  const userDetails = localStorage.getItem("userDetails");
  const user = userDetails ? JSON.parse(userDetails) : {};
  const auth = useAuth();
  const [messages, setMessages] = useState(romCoState?.messageList?.length > 0 ? romCoState?.messageList : initialMessages);
  const [newMessage, setNewMessage] = useState('');
  const [questionAsked, setQuestionAsked] = useState(false);
  const [questionList, setQuestionList] = useState([]);
  const [answerList, setAnswerList] = useState([]);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [recording, setRecording] = useState(false);
  const [awaitingUploadResponse, setAwaitingUploadResponse] = useState(true);
  const [file, setFile] = useState(null);
  const [upload, setUpload] = useState(false);

  console.log(romCoState, 'romCoState')
  console.log(messages, 'romCoState messages')

  const refreshData = () => {
    setMessageList([]);
    setMessages(initialMessages);
    setNewMessage('');
    setQuestionAsked(false);
    updateRomCoState('questionAsked', false)
    updateRomCoState('messageList', [])
    setQuestionList([]);
    setAnswerList([]);
    setCurrentQuestionIndex(0);
    setRecording(false);
    setAwaitingUploadResponse(true);
    updateRomCoState('awaitingUploadResponse', true)
    setFile(null);
  }

  useEffect(() => {
    setQuestionAsked(romCoState?.questionAsked)
    setAwaitingUploadResponse(romCoState?.awaitingUploadResponse)
    setUpload(romCoState?.upload)
  }, []);

  useEffect(() => {
    if (refresh) {
      refreshData();
      setRefresh(false);
    }
  }, [refresh]);

  // useEffect(() => {
  //   if (messages?.length > 0) {
  //     updateRomCoState('messageList', messages)
  //   }
  // }, [messages]);

  useEffect(() => {
    const scrollToBottom = () => {
      const lastMessageElem = document.querySelector('.chat-list .ant-list-item:last-child');
      if (lastMessageElem) {
        lastMessageElem.scrollIntoView({ behavior: 'smooth', block: 'end' });
      }
    };

    scrollToBottom();
  }, [messages]);

  const handleSendMessage = async (userResponse) => {
    if (!newMessage.trim() && !userResponse && !file) {
      message.error("Please enter some text or upload a file!");
      return;
    }

    if (awaitingUploadResponse) {
      const userResponseMessage = {
        id: messages.length + 1,
        content: userResponse,
        role: 'user',
      };
      //setMessages(messages => [...messages, userResponseMessage]);

      setMessages(
        messages => {
          const updatedMessages = [...messages, userResponseMessage];
          updateRomCoState('messageList', updatedMessages)
          return updatedMessages;
        }
      );

      if (userResponse.toLowerCase() === 'yes') {
        // setMessages(messages => [
        //   ...messages,
        //   { id: messages.length + 2, content: "Please upload your RFP file.", role: 'assistant' }
        // ]);
        setMessages(
          messages => {
            const updatedMessages = [...messages, { id: messages.length + 2, content: "Please upload your RFP file.", role: 'assistant' }];
            updateRomCoState('messageList', updatedMessages)
            return updatedMessages;
          }
        );
        setUpload(true);
        updateRomCoState('upload', true)
      } else {
        // Add loading message for fetching questions
        const loadingMessage = {
          id: messages.length + 2,
          content: "Loading questions...",
          role: 'assistant',
        };
        setMessages(messages => [...messages, loadingMessage]);
        
        try {
          const botResponse = await fetchBotResponse("I want to create a RFP for Project");
          
          // Replace the loading message with the actual bot response
          // setMessages(messages => messages.map(message =>
          //   message.id === loadingMessage.id ? { ...message, content: botResponse } : message
          // ));
          setMessages(
            messages => {
              const updatedMessages = messages.map(message =>
                message.id === loadingMessage.id ? { ...message, content: botResponse } : message
              )
              updateRomCoState('messageList', updatedMessages)
              return updatedMessages;
            }
          );
        } catch (error) {
          console.error("Fetching bot's response failed:", error);
          // setMessages(messages => messages.map(message =>
          //   message.id === loadingMessage.id ? { ...message, content: "Failed to load questions" } : message
          // ));
          setMessages(
            messages => {
              const updatedMessages = messages.map(message =>
                message.id === loadingMessage.id ? { ...message, content: "Failed to load questions" } : message
              );
              updateRomCoState('messageList', updatedMessages)
              return updatedMessages;
            }
          );
        }
      }
      setNewMessage('');
      updateRomCoState('awaitingUploadResponse', false)
      setAwaitingUploadResponse(false);
      return;
    }

    if (!questionAsked) {
      // User message
      const nextYouMessage = {
        id: messages.length + 1,
        content: newMessage,
        role: 'user',
      };

      // Temporary loading message for bot
      const loadingMessage = {
        id: messages.length + 2,
        content: "Loading bot's response...",
        role: 'assistant',
      };

      // Update state with user message and temporary loading message
      setMessages(messages => [...messages, nextYouMessage, loadingMessage]);
      setNewMessage('');
      setMessageList(messages => [...messages, nextYouMessage, loadingMessage]);

      try {
        const botResponse = await fetchBotResponse(newMessage);

        // Replace the loading message with actual bot response
        // setMessages(messages => messages.map(message =>
        //   message.id === loadingMessage.id ? { ...message, content: botResponse } : message
        // ));

        setMessages(
          messages => {
            const updatedMessages = messages.map(message =>
              message.id === loadingMessage.id ? { ...message, content: botResponse } : message
            );
            updateRomCoState('messageList', updatedMessages)
            return updatedMessages;
          }
        );

        setMessageList(messages => messages.map(message =>
          message.id === loadingMessage.id ? { ...message, content: botResponse } : message
        ));
      } catch (error) {
        console.error("Fetching bot's response failed:", error);
        // Update loading message with error message
        // setMessages(messages => messages.map(message =>
        //   message.id === loadingMessage.id ? { ...message, content: "Failed to load response" } : message
        // ));

        setMessages(
          messages => {
            const updatedMessages = messages.map(message =>
              message.id === loadingMessage.id ? { ...message, content: "Failed to load response" } : message
            );
            updateRomCoState('messageList', updatedMessages)
            return updatedMessages;
          }
        );

        setMessageList(messages => messages.map(message =>
          message.id === loadingMessage.id ? { ...message, content: "Failed to load response" } : message
        ));
      }
      return;
    }

    // User response to a question
    const nextUserMessage = {
      id: messages.length + 1,
      content: userResponse,
      role: 'user'
    };
    setAnswerList(answerList => [...answerList, userResponse]);

    // Check if there are more questions
    if (currentQuestionIndex < questionList.length - 1) {
      const botMessage = {
        id: messages.length + 2,
        content: questionList[currentQuestionIndex + 1],
        role: 'assistant',
      };
      //setMessages(messages => [...messages, nextUserMessage, botMessage]);
      setMessages(
        messages => {
          const updatedMessages = [...messages, nextUserMessage, botMessage];
          updateRomCoState('messageList', updatedMessages)
          return updatedMessages;
        }
      );
      setNewMessage('');
      setCurrentQuestionIndex(current => current + 1);
    } else {
      const loadingMessage = {
        id: messages.length + 2,
        content: "Loading ROM response...",
        role: 'assistant',
      };
      setMessages(messages => [...messages, nextUserMessage, loadingMessage]);
      setNewMessage('');
      try {
        const botResponse = await fetchROMResponse([...answerList, nextUserMessage]);

        // Replace the loading message with actual bot response
        // setMessages(messages => messages.map(message =>
        //   message.id === loadingMessage.id ? { ...message, content: botResponse } : message
        // ));
        setMessages(
          messages => {
            const updatedMessages = messages.map(message =>
              message.id === loadingMessage.id ? { ...message, content: botResponse } : message
            );
            updateRomCoState('messageList', updatedMessages)
            return updatedMessages;
          }
        );
        setMessageList(messages => messages.map(message =>
          message.id === loadingMessage.id ? { ...message, content: botResponse } : message
        ));
      } catch (error) {
        console.error("Fetching bot's response failed:", error);
        // Update loading message with error message
        // setMessages(messages => messages.map(message =>
        //   message.id === loadingMessage.id ? { ...message, content: "Failed to load response" } : message
        // ));
        setMessages(
          messages => {
            const updatedMessages = messages.map(message =>
                message.id === loadingMessage.id ? { ...message, content: "Failed to load response" } : message
              );
              updateRomCoState('messageList', updatedMessages)
            return updatedMessages;
          }
        );
        setMessageList(messages => messages.map(message =>
          message.id === loadingMessage.id ? { ...message, content: "Failed to load response" } : message
        ));
      }
      setQuestionAsked(false);
      updateRomCoState('questionAsked', false)
      setCurrentQuestionIndex(0);
      setAnswerList([]);
    }
  };

  const fetchBotResponse = async (query) => {
    try {
      let apiEndpoint = "rom_suggested_question";

      let formData = new FormData();
      formData.append("query", query);

      const response = await fetch(`${process.env.REACT_APP_API_URL}/${apiEndpoint}`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${auth.user['access_token']}`,
        },
        body: formData
      });
      if (response.ok) {
        const data = await response.json();
        if (data.error) {
          return data.error;
        }
        if (data.question_list) {
          setQuestionAsked(true);
          updateRomCoState('questionAsked', true)
          setQuestionList(data.question_list);
          return data.question_list[0];
        }
        return data.response;
      }
    } catch (err) {
      return err;
    }
  }

  const fetchROMResponse = async (_answerList) => {
    try {
      let apiEndpoint = "get_rom_response";

      let formData = new FormData();

      // Concatenate all questions and answers into a single string
      let conversation = "";
      for (let i = 0; i < questionList.length; i++) {
          conversation += `Question: ${questionList[i]}\nAnswer: ${_answerList[i]}\n\n`;
      }
      formData.append("conversation", conversation);
      const response = await fetch(`${process.env.REACT_APP_API_URL}/${apiEndpoint}`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${auth.user['access_token']}`,
        },
        body: formData
      });
      if (response.ok) {
        const data = await response.json();
        if (data.error) {
          return data.error;
        }
        return data.response;
      }
    } catch (err) {
      return err;
    }
  }

  useEffect(() => {
    if (!recognition) return;

    recognition.onresult = (event) => {
      const transcript = Array.from(event.results)
        .map((result) => result[0])
        .map((result) => result.transcript)
        .join('');
      setNewMessage(transcript);
      setRecording(false);
    };

    recognition.onend = () => {
      setRecording(false);
    };

    recognition.onerror = (event) => {
      console.error('SpeechRecognition error:', event.error);
      setRecording(false);
    };
  }, []);

  const handleRecord = () => {
    if (!SpeechRecognition) {
      console.error('SpeechRecognition is not supported in this browser.');
      return;
    }
    if (recording) {
      recognition.stop();
      setRecording(false);
    } else {
      recognition.start();
      setRecording(true);
    }
  };

  const markdownToHtml = (markdown) => {
    const rawHtml = marked.parse(markdown);
    return DOMPurify.sanitize(rawHtml);
  };

  const handleFileUpload = ({ file, onSuccess }) => {
    setFile(file);
    setTimeout(() => {
      onSuccess("ok");
    }, 0);
  };

  const handleFileSubmit = async () => {
    if (!file) {
      message.error("Please select a file to upload!");
      return;
    }

    const formData = new FormData();
    formData.append('file', file);

    const newMessage = {
      id: messages.length + 1,
      content: "File uploaded successfully.",
      role: 'user',
    };

    const loadingMessage = {
      id: messages.length + 2,
      content: "Loading ROM response...",
      role: 'assistant',
    };
    setMessages(messages => [...messages, newMessage, loadingMessage]);

    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/rom_file`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${auth.user['access_token']}`,
        },
        body: formData,
      });

      if (response.ok) {
        const data = await response.json();
        // Replace the loading message with actual bot response
        // setMessages(messages => messages.map(message =>
        //   message.id === loadingMessage.id ? { ...message, content: data } : message
        // ));
        setMessages(
          messages => {
            const updatedMessages = messages.map(message =>
                message.id === loadingMessage.id ? { ...message, content: data } : message
              );
              updateRomCoState('messageList', updatedMessages)
            return updatedMessages;
          }
        );
        setAwaitingUploadResponse(false);
        updateRomCoState('awaitingUploadResponse', false)
        setFile(null);
        updateRomCoState('upload', false)
        setUpload(false);
      } else {
        message.error('File upload failed.');
        //setMessages(messages => messages.filter(message => message.id !== loadingMessage.id));
        setMessages(
          messages => {
            const updatedMessages = messages.filter(message => message.id !== loadingMessage.id);
            updateRomCoState('messageList', updatedMessages)
            return updatedMessages;
          }
        );
      }
    } catch (error) {
      console.error('File upload error:', error);
      message.error('File upload failed.');
      //setMessages(messages => messages.filter(message => message.id !== loadingMessage.id));
      setMessages(
        messages => {
          const updatedMessages = messages.filter(message => message.id !== loadingMessage.id);
          updateRomCoState('messageList', updatedMessages)
          return updatedMessages;
        }
      );
    }
  };

  return (
    <>
      <div className="chat-container">
        <List
          className="chat-list"
          dataSource={messages}
          renderItem={(item, index) => (
            <List.Item key={item.id} className={`chat-message ${item.role} custom-list-item`} style={{ padding: "10px", borderRadius: "10px" }}>
              <List.Item.Meta
                avatar={item.role === 'assistant' ? <Avatar src={botAvatar} style={{ backgroundColor: "#000000", padding: "5px" }} className="avatar-left" /> : <Avatar className="avatar-right" style={{ backgroundColor: "#fff", color: "#0074D9", marginRight: "10px" }}>{user.name.split(" ").length > 1
                  ? `${user.name.split(" ")[0][0]}${user.name.split(" ")[1][0]}`
                  : `${user.name.split(" ")[0][0]}${user.name.split(" ")[0][1]}`}</Avatar>}
                title={item.role === 'user' ? 'You' : 'Eerly AI Copilot'}
                description={
                  item.content === "Loading bot's response..." ?
                    <Spin />
                    :
                    item.content === "Loading ROM response..." ?
                      <Row>
                        <Col span={24}>
                          <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                            <Spin />
                            <Text strong style={{ marginLeft: "15px" }}>Generating ROM response</Text>
                          </div>
                        </Col>
                      </Row>
                      :
                      item.content === "Loading questions..." ?
                        <Spin />
                        :
                        <>
                          <div className="message-content" dangerouslySetInnerHTML={{ __html: markdownToHtml(item.content) }} />
                          {item.role === 'assistant' &&
                            <>
                              {awaitingUploadResponse && index === messages.length - 1 && (
                                <div style={{ marginTop: 20 }}>
                                  <Radio.Group onChange={(e) => handleSendMessage(e.target.value)}>
                                    <Radio.Button value="Yes">Yes</Radio.Button>
                                    <Radio.Button value="No">No</Radio.Button>
                                  </Radio.Group>
                                </div>
                              )}
                              {upload && index === messages.length - 1 && (
                                <div style={{ marginTop: 20, display: 'flex' }}>
                                  <Upload customRequest={handleFileUpload}>
                                    <Button icon={<UploadOutlined />}>Select your RFP file</Button>
                                  </Upload>
                                  <Button type="primary" onClick={handleFileSubmit} style={{ marginLeft: 10 }}>Upload</Button>
                                </div>
                              )}
                              <Row>
                                <Col lg={12} md={12} sm={12} xs={4}>
                                  <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
                                    <CopytoClipboard text={item.content} />
                                    {item.content.includes("ROM for") && <DownloadDocButton markdownText={item.content} fileName="ROM Response" />}
                                  </div>
                                </Col>
                                <Col lg={12} md={12} sm={12} xs={20}>
                                  <DummyFeedback />
                                </Col>
                              </Row>
                            </>
                          }
                        </>
                }
              />
            </List.Item>
          )}
        />
      </div>
      {!awaitingUploadResponse && !upload && (
        questionAsked ? (
          <Space.Compact style={{ width: '100%', marginTop: "20px" }}>
            <Button type="primary" onClick={handleRecord} icon={<AudioOutlined />} danger={recording} style={{ height: '100px' }} />
            <TextArea
              style={{ resize: 'none', height: '100px' }}
              value={newMessage}
              onChange={(e) => setNewMessage(e.target.value)}
              onPressEnter={() => handleSendMessage(newMessage)}
              placeholder="Type your response here..."
            />
            <Button type="primary" onClick={() => handleSendMessage(newMessage)} icon={<SendOutlined />} style={{ height: '100px' }} />
          </Space.Compact>
        ) : (
          <Space.Compact style={{ width: '100%', marginTop: "20px" }}>
            <Button type="primary" onClick={handleRecord} icon={<AudioOutlined />} danger={recording} />
            <Input
              value={newMessage}
              onChange={(e) => setNewMessage(e.target.value)}
              onPressEnter={() => handleSendMessage(newMessage)}
              placeholder="Ask your query..."
            />
            <Button type="primary" onClick={async () => {
              await handleSendMessage(newMessage)
            }} icon={<SendOutlined />} />
          </Space.Compact>
        )
      )}
    </>
  );
};

export default ROMCopilot;
