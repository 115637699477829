import React from 'react'
import { Layout, Row, Col, Typography, Switch, Tooltip, Radio, Flex } from 'antd';
import ChatbotEerlyInsight from '../ChatbotEerlyInsight/ChatbotEerlyInsight'
const { Content } = Layout;

const EerlyInsightsChat = () => {
    const userDetails = localStorage.getItem("userDetails");
    const user = userDetails && JSON.parse(userDetails);
    return (
        <Content style={{ overflow: 'initial' }} className="site-layout-background mobile-layout-margin">
            <Row>
                <Col span={24}>
                    <ChatbotEerlyInsight user={user} />
                </Col>
            </Row>
        </Content>
    )
}

export default EerlyInsightsChat