import React from 'react';
import { Document, Packer, Paragraph, TextRun } from "docx";
import { saveAs } from 'file-saver';
import MarkdownIt from 'markdown-it';
import { Button, Dropdown, Menu, message } from 'antd';
import { DownloadOutlined } from '@ant-design/icons';
import { marked } from 'marked';
// import htmlDocx from 'html-docx-js';
import { asBlob } from 'html-docx-js-typescript'
import PptxGenJS from 'pptxgenjs';
import { useAuth } from '../../auth/AuthContext';
// const mdParser = new MarkdownIt();

const DownloadButton = ({ markdownText, messages, fileName = 'Chat Conversation', disabled }) => {
  const auth = useAuth();
  const [messageApi, contextHolder] = message.useMessage();
  const key = 'updatable';
  const handleDownload = () => {
    let htmlDoc = ``
    messages.forEach((msg)=> {
      htmlDoc += `## ${msg.role === "user" ? "You" : "Bot"}: 
${typeof msg.content === 'object' && msg.role !== "user"? msg.content.map((response, index) => (`\n### Response ${String(Number(index)+1)}: \n${response.synthesized_response}\n`)) : msg.content}

`
    })
    
    const htmlContent = marked(htmlDoc);
    asBlob(htmlContent).then((data) => {
      saveAs(data, "Chat-Conversation.docx") // save as docx file
    })

  };

  const estimateTextHeight = (text, fontSize, lineWidth, linePadding = 1.2) => {
    const charsPerLine = Math.floor(lineWidth / (fontSize * 0.5)); // Estimate chars per line. Adjust the multiplier based on your font.
    const numberOfLines = Math.ceil(text.length / charsPerLine);
    return numberOfLines * fontSize * linePadding; // Line padding for spacing between lines
  };

  const downloadSummary = async() => {
    messageApi.open({
      key,
      type: 'loading',
      content: 'Downloading...',
      duration: 0,
    });
    let htmlDoc = ``
    messages.forEach((msg)=> {
      htmlDoc += `${msg.role === "user" ? "You" : "Bot"}: ${typeof msg.content === 'object' && msg.role !== "user"? msg.content.map((response, index) => (`\n### Response ${String(Number(index)+1)}: \n${response.synthesized_response}\n`)) : msg.content} \n`
    })
    const formData = new FormData();
    formData.append("chat_transcript", htmlDoc)

    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/chat_summary`, {
          method: 'POST',
          headers: {
              'Authorization': `Bearer ${auth.user['access_token']}`,
          },
          body: formData,
      });
      const data = await response.json();
      const htmlContent = marked(data);
      asBlob(htmlContent).then((data) => {
        saveAs(data, "Chat-Summary.docx");
        messageApi.open({
          key,
          type: 'success',
          content: 'Downloaded!',
          duration: 2,
        });
      })
    } catch (error) {
      messageApi.open({
        key,
        type: 'error',
        content: 'An error occurred during generating chat summary.',
        duration: 2,
      });
      console.error('Error:', error);
    }
  }
  
  const generatePPT = () => {
    let pptx = new PptxGenJS();
    const slideWidth = 10; // Example slide width in inches
    const textMargin = 1; // Margin on each side in inches
    const lineWidth = slideWidth - 2 * textMargin; // Effective line width
    // Helper function to simplify Markdown content to plain text
    const simplifyMarkdown = (text) => {
        return text
            .replace(/\*\*(.*?)\*\*/g, '$1') // Bold to plain text
            .replace(/\*(.*?)\*/g, '$1') // Italic to plain text
            .replace(/\`(.*?)\`/g, '$1') // Code to plain text
            .replace(/\#/g, ''); // Code to plain text
    };

    for (let i = 0; i < messages.length; i += 2) {
        let slide = pptx.addSlide();

        // First message
        let msg1 = messages[i];
        let content1 = typeof msg1.content === 'object' ? JSON.stringify(msg1.content, null, 2) : simplifyMarkdown(msg1.content);
        // let estimatedHeight1 = estimateTextHeight(content1, 12, lineWidth);
        slide.addText(`Sender: You`, { x: 0.5, y: 0.3, fontSize: 12, bold: true });
        slide.addText(content1, { x: 0.5, y: 0.5, fontSize: 10, w: "90%", h: "20%", valign: 'top' });
        
        // Second message (if exists)
        if (i + 1 < messages.length) {
            let msg2 = messages[i + 1];
            let content2 = typeof msg2.content === 'object' ? 
            msg2.content.map((response, index) => (`Response ${String(Number(index)+1)}: ${response.synthesized_response}`)).join("\n\n") : 
            simplifyMarkdown(msg2.content);
            // let estimatedHeight2 = estimateTextHeight(content2, 10, lineWidth);
            slide.addText(`Sender: Bot`, { x: 0.5, y: 1, fontSize: 12, bold: true });
            slide.addText(content2, { x: 0.5, y: 1.2, fontSize: 10, w: "90%", h: "100%", valign: 'top' });
        }
    }
  
    pptx.writeFile({ fileName: "Chat-Conversation.pptx" });
  };


  const menu = (
    <Menu>
      <Menu.Item key="1" onClick={handleDownload}>
        Download as DOCX
      </Menu.Item>
      <Menu.Item key="2" onClick={generatePPT}>
        Download as PPT
      </Menu.Item>
      <Menu.Item key="3" onClick={downloadSummary}>
        Download Chat Summary
      </Menu.Item>
    </Menu>
  );

  return (
    <>
      {contextHolder}
      <Dropdown overlay={menu} trigger={['click']}>
        <a onClick={e => e.preventDefault()}>
          <Button shape="circle" type="primary" icon={<DownloadOutlined />} disabled={disabled} />
        </a>
      </Dropdown>
    </>
  );
};

export default DownloadButton;
