import React, { createContext, useReducer } from 'react';

// Create a Context
export const AppContext = createContext();

// Initial States
const initialState = {
    messageList: [],
    messages: [
        { id: 1, content: "Hello, What can I do for you?", role: 'assistant' },
    ],
    suggestedQuestionsOn: false,
    initialized: false,
    chatInitiated: false,
    value: 'New Chat',
    options: [
        { label: 'New Chat', value: 'New Chat' },
        { label: 'Chat History', value: 'Chat History' },
    ],
    suggestedQuestions: [],
    switchValue: 0,
    switch1Disabled: false,
    switch2Disabled: false
};

const RfpCoInitialState = {
    messageList: [],
    questionAsked: false
};

const RomCoInitialState = {
    messageList: [],
    awaitingUploadResponse: true,
    upload: false,
    questionAsked: false
};

const WoCoInitialState = {
    messageList: [],
    awaitingUploadResponse: true,
    upload: false,
    questionAsked: false
};

const EerlyInsightState = {
    messageList: [],
    chatInitiated: false
};

const PolicyAssistantState = {
    messageList: [],
    chatInitiated: false
};

// Reducer function
const reducer = (state, action) => {
    switch (action.type) {
        case 'UPDATE_STATE':
            return {
                ...state,
                [action.key]: action.value,
            };
        default:
            return state;
    }
};

// AppProvider using useReducer
export const AppProvider = ({ children }) => {
    const [sapAiState, sapAiDispatch] = useReducer(reducer, initialState);
    const [rfpCoState, rfpCoDispatch] = useReducer(reducer, RfpCoInitialState);
    const [romCoState, romCoDispatch] = useReducer(reducer, RomCoInitialState);
    const [woCoState, woCoDispatch] = useReducer(reducer, WoCoInitialState);
    const [eerlyInsightState, eerlyInsightDispatch] = useReducer(reducer, EerlyInsightState);
    const [policyAssistantState, policyAssistantStateDispatch] = useReducer(reducer, PolicyAssistantState);

    // Function to update sapAiState
    const updateSapAiState = (key, value) => {
        sapAiDispatch({
            type: 'UPDATE_STATE',
            key,
            value,
        });
    };

    // Function to update rfpCoState
    const updateRfpCoState = (key, value) => {
        rfpCoDispatch({
            type: 'UPDATE_STATE',
            key,
            value,
        });
    };

    const updateRomCoState = (key, value) => {
        romCoDispatch({
            type: 'UPDATE_STATE',
            key,
            value,
        });
    };

    const updateWoCoState = (key, value) => {
        woCoDispatch({
            type: 'UPDATE_STATE',
            key,
            value,
        });
    };

    const updateEerlyInsightState = (key, value) => {
        eerlyInsightDispatch({
            type: 'UPDATE_STATE',
            key,
            value,
        });
    };

    const updatePolicyAssistantState = (key, value) => {
        policyAssistantStateDispatch({
            type: 'UPDATE_STATE',
            key,
            value,
        });
    };

    return (
        <AppContext.Provider value={{ 
            sapAiState, 
            updateSapAiState, 
            rfpCoState, 
            updateRfpCoState, 
            romCoState, 
            updateRomCoState, 
            woCoState, 
            updateWoCoState, 
            eerlyInsightState, 
            updateEerlyInsightState ,
            policyAssistantState,
            updatePolicyAssistantState
        }}>
            {children}
        </AppContext.Provider>
    );
};
