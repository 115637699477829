// src/pages/OTPValidation.tsx
import React, { useState, useRef } from 'react';
import { Layout, Row, Col, Form, Input, Button, Card, Typography, message } from 'antd';
import AppFooter from '../../AppFooter/AppFooter';
import logo from '../../../assets/images/Eerly_logo_color-transparent.png';
import { useNavigate } from 'react-router-dom';
const { Content } = Layout;
const { Title, Text } = Typography;

const OTPValidation = () => {
  const [otp, setOtp] = useState(new Array(6).fill(''));
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const [official_email, setOfficialEmail] = useState(localStorage.getItem("userEmail"));
  const otpRefs = useRef([]);
  const navigate = useNavigate();

  const handleOtpChange = (e, index) => {
    const { value } = e.target;
    if (isNaN(value)) return;

    const newOtp = [...otp];
    newOtp[index] = value;
    setOtp(newOtp);

    // Focus next input
    if (value && index < otpRefs.current.length - 1) {
      otpRefs.current[index + 1].focus();
    }
  };

  const handleKeyDown = (e, index) => {
    if (e.key === 'Backspace' && index > 0 && !otp[index]) {
      otpRefs.current[index - 1].focus();
    }
  };

  const handleResetPassword = async () => {
    if (newPassword !== confirmPassword) {
      message.error('Passwords do not match');
      return;
    }

    setLoading(true);
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/forgot_password_confirm`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'accept': 'application/json',
        },
        body: JSON.stringify({ official_email: official_email, new_password: newPassword, confirm_password: confirmPassword, otp: otp.join('') }),
      });
      localStorage.removeItem('userEmail');
      if (!response.ok) {
        const errorData = await response.json();
        message.error(errorData.detail || 'Failed to reset password');
        throw new Error(errorData.detail || 'Failed to reset password');
      }

      message.success('Password has been reset successfully');
      navigate('/login');
    } catch (error) {
      message.error("Error: " + error);
      console.error("Error:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Layout style={{ minHeight: '100vh', backgroundColor: '#E9E9EB' }}>
      <Content style={{ padding: '0', textAlign: 'center' }}>
        <Row justify="center" align="middle">
          <Col xs={24} sm={24} lg={9}></Col>
          <Col xs={24} sm={24} lg={6} style={{ paddingTop: '12%', padding: '20px' }}>
            <Card style={{ padding: '30px', boxShadow: '0px 0px 23px -1px rgba(133,133,133,1)' }}>
              <img src={logo} alt="Eerly Logo" width={'150px'} />
              <Title style={{ marginTop: '-10px', marginBottom: '50px', color: '#011470' }} level={4}>
                Eerly AI Brain by Eerly AI
              </Title>
              <Title level={3}>Reset Password</Title>
              <Text>Enter the OTP sent to your email and your new password.</Text>
              <Form
                style={{ textAlign: 'left', marginTop: '20px' }}
                layout="vertical"
                onFinish={handleResetPassword}
              >
                <Form.Item
                  label="OTP"
                  name="otp"
                  rules={[
                    {
                      required: true,
                      message: 'Please enter the OTP sent to your email!',
                    },
                  ]}
                >
                  <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    {otp.map((data, index) => (
                      <Input
                        key={index}
                        ref={(el) => (otpRefs.current[index] = el)}
                        type="text"
                        maxLength="1"
                        value={data}
                        onChange={(e) => handleOtpChange(e, index)}
                        onKeyDown={(e) => handleKeyDown(e, index)}
                        style={{ width: '40px', textAlign: 'center', marginRight: '5px' }}
                      />
                    ))}
                  </div>
                </Form.Item>
                <Form.Item
                  label="New Password"
                  name="newPassword"
                  rules={[
                    {
                      required: true,
                      message: 'Please enter your new password!',
                    },
                  ]}
                >
                  <Input.Password
                    placeholder="Enter new password"
                    value={newPassword}
                    onChange={(e) => setNewPassword(e.target.value)}
                  />
                </Form.Item>
                <Form.Item
                  label="Confirm New Password"
                  name="confirmPassword"
                  rules={[
                    {
                      required: true,
                      message: 'Please confirm your new password!',
                    },
                  ]}
                >
                  <Input.Password
                    placeholder="Confirm new password"
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                  />
                </Form.Item>
                <Form.Item>
                  <Button
                    type="primary"
                    style={{ width: '100%' }}
                    htmlType="submit"
                    loading={loading}
                  >
                    Reset Password
                  </Button>
                </Form.Item>
              </Form>
            </Card>
          </Col>
          <Col xs={24} sm={24} lg={9}></Col>
        </Row>
        <div style={{ position: "fixed", bottom: 10, width: "100%" }}>
          <AppFooter />
        </div>
      </Content>
    </Layout>
  );
};

export default OTPValidation;
