import React, {useState} from 'react'
import { Layout, Row, Col, Typography, Button, Tooltip } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import RFPCopilot from './RFPCopilot/RFPCopilot';
import SOWCopilot from './SOWCopilot/SOWCopilot';
import ROMCopilot from './ROMCopilot/ROMCopilot';
import PermissionDenied from '../PermissionDenied/PermissionDenied';
const { Content } = Layout;
const {Title} = Typography;

const Copilot = ({copilotType}) => {
  const [messageList, setMessageList] = useState([]);
  const [refresh, setRefresh] = useState([]);
  const userDetails = localStorage.getItem("userDetails");
  const user = userDetails && JSON.parse(userDetails);
  return (
    user.plan === 'Free' && user.role !== 'admin' ?
    <PermissionDenied />
    :
    <Content style={{ overflow: 'initial' }} className="site-layout-background mobile-layout-margin">
      <Row>
        <Col span={16}>
          <Title level={3} style={{marginTop: 0}}>{copilotType === "rfp"? "RFP Copilot" : copilotType === "sow" ? "Work Order Copilot" : "ROM Copilot" }</Title>
        </Col>
        <Col span={8} style={{textAlign: "right"}}>
          <Tooltip placement="top" title={"Clear Chat"}>
            <Button type="primary" onClick={()=>setRefresh(true)} danger><DeleteOutlined /></Button>
          </Tooltip>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          {copilotType === "rfp"? <RFPCopilot setMessageList={setMessageList} refresh={refresh} setRefresh={setRefresh} /> : copilotType === "sow" ? <SOWCopilot setMessageList={setMessageList} refresh={refresh} setRefresh={setRefresh} /> : <ROMCopilot setMessageList={setMessageList} refresh={refresh} setRefresh={setRefresh} /> }
        </Col>
      </Row>
    </Content>
    
    )
}

export default Copilot;