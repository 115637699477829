// src/App.tsx
import React from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import ProtectedRoute from './auth/ProtectedRoute';
import Login from './components/Login/Login';
import Signup from './components/Signup/Signup';
import LayoutWithLeftMenu from './components/LayoutWithLeftMenu/LayoutWithLeftMenu';
// import Dashboard from './components/Dashboard/Dashboard';
// import ChatAI from './components/Chat/ChatAI/ChatAI';
import ChatWithData from './components/Chat/ChatWithData/ChatWithData';
import Copilot from './components/Copilot/Copilot'
import "./App.css";
import { useAuth } from "./auth/AuthContext";
// import Subscribe from './components/Subscribe/Subscribe';
// import ResetPassword from './components/ResetPassword/ResetPassword';
import CheckoutForm from './components/CheckoutForm/CheckoutForm';
import Return from './components/Return/Return';
import ChangePassword from './components/ChangePassword/ChangePassword';
import ResetPassword from './components/Password/OTPValidation/OTPValidation';
import ForgotPassword from './components/Password/ForgotPassword/ForgotPassword';
import VerifyEmail from './components/Signup/VerifyEmail/VerifyEmail';
import VerifiedEmail from './components/Signup/VerifiedEmail/VerifiedEmail';
import EerlyInsights from './components/EerlyInsights/EerlyInsights';
import LinkedInCallback from './components/LinkedInLogin/LinkedInCallback';
import { AppProvider } from './context/AppContext';
import EerlyInsightsChat from './components/EerlyInsightsChat/EerlyInsightsChat';
import PolicyAssistant from './components/PolicyAssistant/PolicyAssistant';


function App() {
  const auth = useAuth();
  return (
    <AppProvider>
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/signup" element={<Signup />} />
        <Route path="/verify-email" element={<VerifyEmail />} />
        <Route path="/email-verified" element={<VerifiedEmail />} />
        {/* <Route path="/subscribe" element={<Subscribe />}  /> */}
        <Route path="/checkout" element={<CheckoutForm />} />
        <Route path="/return" element={<Return />} />
        {/* <Route path="/reset-password" element={<ProtectedRoute><ResetPassword /></ProtectedRoute>} /> */}
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/reset-password/" element={<ResetPassword />} />
        <Route path="/change-password" element={<ProtectedRoute><ChangePassword /></ProtectedRoute>} />
        <Route path="/callback/linkedin" element={<LinkedInCallback />} />
        <Route element={<ProtectedRoute><LayoutWithLeftMenu /></ProtectedRoute>}>
          {/* <Route path="/overview" element={<Dashboard />} /> */}
          {/* <Route path="/chat-ai" element={<ChatAI />} /> */}
          <Route path="/eerly-brain" element={<ChatWithData />} />
          {/* <Route path="/eerly-insights" element={<EerlyInsights />} /> */}
          <Route path="/policy-assistant" element={<PolicyAssistant />} />
          <Route path="/eerly-insights" element={<EerlyInsightsChat />} />
          <Route path="/rfp-copilot" element={<Copilot copilotType="rfp" />} />
          <Route path="/work-order-copilot" element={<Copilot copilotType="sow" />} />
          <Route path="/rom-copilot" element={<Copilot copilotType="rom" />} />
        </Route>
        <Route path="/" element={auth.user ? <Navigate to="/eerly-brain" /> : <Login />} />
      </Routes>
    </AppProvider>
  );
}

export default App;
