import React, { useState, useEffect } from "react";
import { Navigate } from "react-router-dom";
import { Row, Col } from 'antd';
import './Return.css'; // Assume you have a CSS file for styling
import AppFooter from "../AppFooter/AppFooter"
import { useAuth } from '../../auth/AuthContext';

const Return = () => {
  const auth = useAuth();
  const [status, setStatus] = useState(null);
  const [customerEmail, setCustomerEmail] = useState('');
  const [countdown, setCountdown] = useState(5);
  const planDetails = localStorage.getItem("plan");
  const plan = JSON.parse(planDetails);
  

  useEffect(() => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const sessionId = urlParams.get('session_id');

    fetch(`${process.env.REACT_APP_API_URL}/session-status?session_id=${sessionId}`)
      .then((res) => res.json())
      .then((data) => {
        setStatus(data.status);
        setCustomerEmail(data.customer_email);
      });
  }, []);

  useEffect(() => {
    if (status === 'complete') {
      updatePlan();
      const timer = setInterval(() => {
        setCountdown(prevCountdown => prevCountdown - 1);
      }, 1000);

      return () => clearInterval(timer);
    }
  }, [status]);

  useEffect(() => {
    if (countdown === 0) {
      window.location.href = '/';
    }
  }, [countdown]);

  const updatePlan = async () => {
    try {      
      const formData = new FormData();
      formData.append("plan_name", plan.tier);
      const response = await fetch(`${process.env.REACT_APP_API_URL}/update-plan`, {
        method: 'POST',
        headers: {
          // 'Content-Type': 'application/x-www-form-urlencoded',
          'Authorization': `Bearer ${auth.user['access_token']}`,
        },
        body: formData
      });

      if (response.ok) {
        const data = await response.json();
        localStorage.removeItem('plan');
      } else {
        console.error('Failed to update plan');
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  if (status === 'open') {
    return <Navigate to="/checkout" />;
  }

  if (status === 'complete') {
    return (
      <>
        <Row>
          <Col xs={24} sm={24} lg={7}></Col>
          <Col xs={24} sm={24} lg={10}>
            <section id="success" className="success-message">
              <h3>
                We appreciate your business! A confirmation email will be sent to {customerEmail}.
                If you have any questions, please email <a href="mailto:info@eerly.ai">info@eerly.ai</a>.
              </h3>
              <h3>
                You will be redirected to the home page in {countdown} seconds.
              </h3>
            </section>
          </Col>
          <Col xs={24} sm={24} lg={7}></Col>
        </Row>
        <div style={{position: "fixed", bottom: 10, width: "100%"}}>
          <AppFooter />
        </div>
      </>
    );
  }

  return null;
}

export default Return;
