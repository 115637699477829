import React, { useState } from 'react';
import { Modal, Button, Form, Input, Select, message } from 'antd';
import { useAuth } from '../../auth/AuthContext';
const { Option } = Select;

const TicketModal = ({ isModalVisible, setIsModalVisible }) => {
  const [form] = Form.useForm();
  const [formData, setFormData] = useState({
    type_of_concern: '',
    concern: ''
  });
  const [loading, setLoading] = useState(false);
  const auth = useAuth();

  const handleChange = (name, value) => {
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleOk = () => {
    form
      .validateFields()
      .then(async (values) => {
        setLoading(true);
        const body = new FormData();
        body.append("type_of_concern", values.type_of_concern);
        body.append("concern", values.concern);
        try {
          const response = await fetch(`${process.env.REACT_APP_API_URL}/create_support_ticket`, {
            method: 'POST',
            headers: { 
                'Authorization': `Bearer ${auth.user['access_token']}`,
            },
            body: body,
          });

          if (!response.ok) {
            const errorData = await response.json();
            message.error(errorData.detail || 'Sorry, can\'t raise a ticket at this moment. Please try after sometime.');
            throw new Error(errorData.detail || 'Raise Ticket Failed');
          }

          const data = await response.json();
          message.success('Your ticket has been raised successfully.');
          form.resetFields();
        } catch (error) {
          message.error(String(error));
          console.error("Error registering:", error);
          throw error;
        } finally {
          setLoading(false);
          setIsModalVisible(false);
        }
      })
      .catch(info => {
        console.error('Validate Failed:', info);
        // Show validation errors if any
      });
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    form.resetFields();
  };

  return (
    <Modal 
      title="Assign a Service Ticket Request" 
      open={isModalVisible} 
      onOk={handleOk} 
      onCancel={handleCancel} 
      centered 
      maskClosable={false} 
      keyboard={false}
      destroyOnClose
      footer={[
          <Button key="cancel" onClick={handleCancel}>
            Cancel
          </Button>,
          <Button key="submit" type="primary" loading={loading} onClick={handleOk}>
            Submit
          </Button>
        ]}
    >
      <Form form={form} layout="vertical" name="ticketForm" style={{ textAlign: "left", marginTop: "20px" }}>
        <Form.Item 
          label="Type of Concern" 
          name="type_of_concern" 
          rules={[{ required: true, message: 'Please select Type of Concern!' }]}
        >
          <Select 
            placeholder="Select a type of concern" 
            onChange={value => handleChange('type_of_concern', value)} 
            value={formData.type_of_concern}
          >
            <Option value="chat">Chat</Option>
            <Option value="co-pilots">Co-pilots</Option>
            <Option value="others">Others</Option>
          </Select>
        </Form.Item>
        <Form.Item 
          name="concern" 
          label="Concern" 
          rules={[{ required: true, message: 'Please describe your concern!' }]}
        >
          <Input.TextArea 
            rows={4} 
            onChange={e => handleChange('concern', e.target.value)} 
            value={formData.concern}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default TicketModal;
