// src/components/LayoutWithLeftMenu.jsx
import {useEffect, useState} from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import Sidebar from '../Sidebar/Sidebar'; 
import { Layout, Avatar, Space, Typography, Spin, Row, Col, Alert } from 'antd';
import { useAuth } from '../../auth/AuthContext';
import AppFooter from '../AppFooter/AppFooter';
import { jwtDecode } from 'jwt-decode';
const { Header } = Layout;

const LayoutWithLeftMenu = () => {    
    const [collapsed, setCollapsed] = useState(window.innerWidth <= 600);
    const [userDetails, setUserDetails] = useState(null);
    const auth = useAuth();
    const navigate = useNavigate();

    useEffect(() => {
        const fetchUserDetails = async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_API_URL}/read_user_details`, {
                    method: 'GET',
                    headers: { 
                        'Authorization': `Bearer ${auth.user['access_token']}`,
                    }
                });
                if (response.ok) {
                    const data = await response.json();
                    
                    localStorage.setItem("userDetails", JSON.stringify(data));
                    setUserDetails(data);
                }else{
                setUserDetails(null);
                }
                
            } catch (error) {
                console.error("Error fetching user details:", error);
            }
        };

        fetchUserDetails();
    }, []);

    useEffect(() => {
        if (auth.user && auth.user['access_token']) {
            const decodedToken = jwtDecode(auth.user['access_token']);
            const currentTime = Date.now() / 1000; // Convert to seconds

            // Use optional chaining and nullish coalescing to handle potential undefined 'exp'
            const expirationTime = decodedToken.exp ?? 0; // Provide a fallback value (0 or another sensible default)
            
            const timeUntilExpiration = (expirationTime - currentTime) * 1000; // Convert back to milliseconds
            
            const logoutTimer = setTimeout(() => {
                auth.logout();
                navigate('/login');
            }, timeUntilExpiration);
    
            return () => clearTimeout(logoutTimer); // Clear the timer if the component unmounts
        }
    }, [auth.user]);
    
    return (
        <div>
        {/* <Row>
          <Col xs={24}>
            <Alert message="Eerly AI Brain is currently down for scheduled maintenance. We'll be back soon. Thank you for your patience!" type="error" style={{position: "fixed", width: "100%", top: 0, zIndex: 1056}} />
          </Col>
        </Row> */}
        <Sidebar collapsed={collapsed} setCollapsed={setCollapsed} />
        {!userDetails? <div style={{textAlign: "center", padding: "30px"}}><Spin tip="Loading" size="large">
                <div className="content" />
            </Spin></div>:
        <Layout  className={collapsed ? 'mobile-sidebar-width' : ''} style={collapsed ? { minHeight: "100vh" } : { marginLeft: 210, minHeight: "100vh" }}>
            <Header style={{ padding: "0 20px", background: '#fff', textAlign: "right" }}>
                <Space><Avatar size={'small'} style={{backgroundColor: "#0074D9", marginBottom: "3%"}}>{userDetails.name.split(" ").length > 1 
                ? `${userDetails.name.split(" ")[0][0]}${userDetails.name.split(" ")[1][0]}` 
                : `${userDetails.name.split(" ")[0][0]}${userDetails.name.split(" ")[0][1]}`}</Avatar><Typography.Text strong>{userDetails.name}</Typography.Text></Space>
            </Header>
            <Outlet /> {/* This is where nested routes will be rendered */}
            <AppFooter width={"92%"} rightAlign={1}/>
        </Layout>}
        </div>
    );
};

export default LayoutWithLeftMenu;
