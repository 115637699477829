import React, { useState } from 'react';
import { Button, Input, Row, message, Col, Alert } from 'antd';
import { DislikeFilled, DislikeOutlined, LikeFilled, LikeOutlined } from '@ant-design/icons';

const { TextArea } = Input;



const DummyFeedback = () => {
  const [feedback, setFeedback] = useState('');
  const [feedbackType, setFeedbackType] = useState(null);
  const [feedbackTypeSubmitted, setfeedbackTypeSubmitted] = useState(false);
  const [submitted, setSubmitted] = useState(false);

  const handleSubmit = async() => {
    if (!feedbackType) {
      message.error('Please select Thumbs Up or Thumbs Down.');
      return;
    }
    setSubmitted(true);    
  };

  return (
        <Row>
            <Col span={24}>
            {submitted?<Alert message="Feedback submitted successfully." type="success" showIcon closable />:
                <div style={{textAlign: "right"}}>
                    <div>
                        <LikeFilled 
                            // shape="circle"
                            type={feedbackType === 'up' ? 'primary' : 'default'} 
                            onClick={() => {setFeedbackType('up'); setfeedbackTypeSubmitted(true);}} 
                            // icon={<LikeFilled />}
                            style={{marginRight: "10px",
                            // backgroundColor: feedbackType === 'up' ? 'white' : undefined, 
                            color: feedbackType === 'up' ? 'green' : undefined }}
                        />
                        <DislikeFilled 
                            // shape="circle"
                            type={feedbackType === 'down' ? 'primary' : 'default'} 
                            onClick={() => {setFeedbackType('down'); setfeedbackTypeSubmitted(true);}}
                            // icon={<DislikeFilled />}
                            style={{ 
                                // backgroundColor: feedbackType === 'down' ? 'white' : undefined, 
                                color: feedbackType === 'down' ? 'red' : undefined 
                              }}
                        />
                    </div>
                    {feedbackTypeSubmitted?
                    <>
                    <div style={{marginTop: "15px"}}><TextArea rows={2} value={feedback} onChange={(e) => setFeedback(e.target.value)} placeholder="Enter your feedback..." />
                    <small style={{color: "red", fontWeight: 600, fontStyle: "italic"}}>Once submitted, you can't edit it back.</small></div>
                    <div style={{textAlign: "right", marginTop: "15px"}}>
                        <Button type="primary" onClick={handleSubmit} disabled={!feedbackType}>Submit</Button>
                    </div>
                    </>
                    :""}
                </div>}
            </Col>
        </Row>
      
  );
};

export default DummyFeedback;
