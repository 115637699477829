// src/components/LinkedInLogin/LinkedInLogin.tsx
import React from 'react';
import { Button } from 'antd';
import {LinkedinOutlined} from '@ant-design/icons';

const LinkedInLogin = () => {
  const handleLogin = () => {
    window.location.href = `${process.env.REACT_APP_API_URL}/login/linkedin`; // Backend endpoint for LinkedIn login
  };

  return (
    <Button type="primary" style={{ marginTop: '10px' }} onClick={handleLogin} icon={<LinkedinOutlined style={{fontSize: "25px"}}/>} shape="circle" size={'large'} />
  );
};

export default LinkedInLogin;
