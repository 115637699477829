// src/pages/Signup.tsx
import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Layout, Row, Col, Form, Typography, Input, Button, Card, message, Checkbox, Modal } from 'antd';
import AppFooter from '../AppFooter/AppFooter';
import logo from '../../assets/images/Eerly_logo_color-transparent.png';
import { useAuth } from '../../auth/AuthContext';

const { Content } = Layout;
const { Title, Paragraph } = Typography;

const ChangePassword = () => {

  const [formData, setFormData] = useState({
    new_password: '',
    confirm_password: ''
  });
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  
  const auth = useAuth();
  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = async () => {
    setLoading(true);
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/reset_password_confirm`, {
        method: 'POST',
        headers: { 
            'Authorization': `Bearer ${auth.user['access_token']}`,
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(formData),
      });

      if (!response.ok) {
        const errorData = await response.json();
        message.error(errorData.detail || 'Sorry, can\'t reset your password at this moment. Please try after sometime.');
        throw new Error(errorData.detail || 'Reset Password Failed');
      }

      const data = await response.json();
      message.success(data.message);
      form.resetFields();
      navigate('/eerly-brain');
    } catch (error) {
      message.error(String(error));
      console.error("Error in reset password:", error);
    } finally {
      setLoading(false);
    }
  };

  const validatePassword = (_, value) => {
    const passwordPattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
    if (!passwordPattern.test(value)) {
      return Promise.reject(new Error('Password must be at least 8 characters long, include an uppercase letter, a lowercase letter, a number, and a special character'));
    }
    return Promise.resolve();
  };

  return (
    <Layout style={{ minHeight: "100vh", backgroundColor: "#E9E9EB" }}>
      <Content style={{ padding: '0', textAlign: "center" }}>
        <Row>
          <Col xs={0} sm={0} lg={7}></Col>
          <Col xs={24} sm={24} lg={10} style={{padding: "10px"}}>
            <Card style={{ marginTop: 100, padding: 30 }}>
              <img src={logo} alt="" width={"150px"} style={{ marginBottom: "20px" }} />
              <Title style={{ color: "#011470", marginTop: 0 }} level={4}>Reset Password</Title>
              <Form layout="vertical" form={form} onFinish={handleSubmit}>
                <Row>
                  <Col xs={24} sm={24} lg={12} style={{paddingRight: "5px", paddingLeft: "5px"}}>
                    <Form.Item
                      label="Password"
                      name="new_password"
                      rules={[{ validator: validatePassword }, { required: true, message: 'Please enter your password!' }]}
                    >
                      <Input.Password name="new_password" value={formData.password} onChange={handleChange} />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} lg={12} style={{paddingRight: "5px", paddingLeft: "5px"}}>
                    <Form.Item label="Confirm Password" name="confirm_password" dependencies={['password']} rules={[
                      { required: true, message: 'Please confirm your password!' },
                      ({ getFieldValue }) => ({
                        validator(_, value) {
                          if (!value || getFieldValue('new_password') === value) {
                            return Promise.resolve();
                          }
                          return Promise.reject(new Error('The two passwords that you entered do not match!'));
                        },
                      }),
                    ]}>
                      <Input.Password name="confirm_password" value={formData.confirm_password} onChange={handleChange} />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} lg={24} style={{paddingRight: "5px", paddingLeft: "5px", marginTop: "20px"}}>
                    <Form.Item>
                      <Button type="primary" htmlType="submit" style={{ width: "100%" }} loading={loading}>
                        RESET PASSWORD
                      </Button>
                    </Form.Item>
                  </Col>
                </Row>
              </Form>
            </Card>
          </Col>
          <Col xs={0} sm={0} lg={7}></Col>
        </Row>
      </Content>
      <AppFooter />
    </Layout>
  );
};

export default ChangePassword;
