import { CopyOutlined } from '@ant-design/icons';
import { Button, message } from 'antd';
import React from 'react'


const CopytoClipboard = ({text}) => {
    const copyToClipboard = async () => {
        try {
            // Convert the response object to a string format if necessary
            const responseText = text;
            await navigator.clipboard.writeText(responseText);
            message.success('Copied!');
        } catch (err) {
            console.error('Failed to copy: ', err);
        }
    };
  return (
    <div>
     <CopyOutlined style={{marginRight: "10px"}} onClick={copyToClipboard} />
    </div>
  )
}

export default CopytoClipboard