import React from 'react';
import { Card, Col, Row, Button, Typography } from 'antd';
import { useNavigate } from 'react-router-dom';
import './PricingTable.css';

const { Title } = Typography;
const pricingData = [
  {
    tier: 'Tier 1',
    price: 'Free',
    features: ['Questions/Answers', 'Co-Pilot', 'History', 'Document Import/Export'],
    gteIndex: 0,
    contact: false,
    priceTier: "price_1PQMuc06LDCXm4KMpFnMFQpj", 
    // priceTier: "price_1PQZM806LDCXm4KMvhFqep03" // Test Env
  },
  {
    tier: 'Tier 2',
    price: '$9.99',
    per: 'per month',
    features: ['Questions/Answers', 'Co-Pilot', 'History', 'Document Import/Export'],
    gteIndex: 2,
    contact: false,
    priceTier: "price_1PQMwV06LDCXm4KMBjlEgMMy", 
    // priceTier: "price_1PQZMb06LDCXm4KMz3pAJYQx" // Test Env
  },
  {
    tier: 'Tier 3',
    price: '$14.99',
    per: 'per month',
    features: ['Questions/Answers', 'Co-Pilot', 'History', 'Document Import/Export'],
    gteIndex: 3,
    contact: false,
    priceTier: "price_1PQMy906LDCXm4KMZ0yjgV26", 
    // priceTier: "price_1PQZMv06LDCXm4KM4HYtb9uj" // Test Env
  },
];

const PricingTable = () => {
  const navigate = useNavigate();
  const userDetails = localStorage.getItem("userDetails");
  const user = userDetails && JSON.parse(userDetails);
  
  return (
    <Row gutter={16} justify="center" className="pricing-table">
      {pricingData.map((plan, index) => (
        <Col lg={6} md={24} xs={24} key={index} style={{ padding: '20px' }}>
          <Card
            className="pricing-card"
            actions={plan.contact ? [<Button type="primary">Contact Us</Button>] : []}
          >
            <Title level={3} style={{ color: '#515151' }}>{plan.tier}</Title>
            <Title level={1} style={{ marginTop: '0px' }}>{plan.price}</Title>
            <Title level={5} style={{ marginTop: '-20px' }}>{plan.per}</Title>
            <hr style={plan.price === 'Free' ? { margin: '60px 60px 20px 60px' } : { margin: '20px 60px' }} />
            <ul className="pricing-features">
              {plan.features.map((feature, i) => (
                <li key={i} style={{ color: i > plan.gteIndex ? 'gray' : 'black' }}><h3>{feature}</h3></li>
              ))}
            </ul>
            {user.plan === "Free"?
            <Button
              onClick={() => navigate('/checkout', { state: { plan } })}
              disabled={plan.price === 'Free'}
              type={plan.price === 'Free' ? 'default' : 'primary'}
              style={{ marginTop: '20px', width: '90%', borderRadius: '20px' }}
            >
              {plan.price === 'Free' ? 'Your Current Plan' : `Upgrade to ${plan.tier}`}
            </Button>
            : user.plan === "Tier 2"?
            plan.price !== 'Free' && 
            <Button
              onClick={() => navigate('/checkout', { state: { plan } })}
              disabled={plan.tier === user.plan}
              type={plan.tier === user.plan ? 'default' : 'primary'}
              style={{ marginTop: '20px', width: '90%', borderRadius: '20px' }}
            >
              {plan.tier ===  user.plan? 'Your Current Plan' : `Upgrade to ${plan.tier}`}
            </Button>
            :plan.price !== 'Free' && plan.price !== 'Tier 2' && 
            <Button
              onClick={() => navigate('/checkout', { state: { plan } })}
              disabled={plan.tier === user.plan}
              type={plan.tier === user.plan ? 'default' : 'primary'}
              style={{ marginTop: '20px', width: '90%', borderRadius: '20px' }}
            >
              {plan.tier ===  user.plan? 'Your Current Plan' : `Upgrade to ${plan.tier}`}
            </Button>
            }
          </Card>
        </Col>
      ))}
    </Row>
  );
};

export default PricingTable;