import React from 'react'
import { Layout } from 'antd';
const { Header, Content, Footer } = Layout;

const AppFooter = ({ width = "90%", rightAlign= 0 }) => {
  return (
    // <Footer style={{ textAlign: 'center', position: "fixed", bottom: 0, width: width, backgroundColor: "transparent", right: rightAlign }}>
    <Footer style={{ textAlign: 'center', backgroundColor: "transparent"}}>
        Eerly AI Brain by Eerly AI ©{new Date().getFullYear()} All rights reserved.
    </Footer>
  )
}

export default AppFooter;